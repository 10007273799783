<template>
  <div class="drill">
    <div class="drill-top">
      <div class="drill-top-context">
        <dv-border-box-1>
          <div class="drill-top-context1">
            <div class="drill-top-left">
              <div class="drill-top-left-title">本月累计人数</div>
              <div class="drill-top-left-num">{{ monthNumList.current }}</div>
              <div class="drill-top-left-context">
                较上月增加{{ monthNumList.current - monthNumList.before }}人
              </div>
            </div>
            <div class="drill-top-right">
              <img
                class="drill-top-right-img"
                src="../../../../assets/img/zhSchool/drill/Icon_BYLJ_RS.png"
              />
            </div>
          </div>
        </dv-border-box-1>
      </div>
      <div class="drill-top-context">
        <dv-border-box-1>
          <div class="drill-top-context1">
            <div class="drill-top-left">
              <div class="drill-top-left-title">本月累计次数</div>
              <div class="drill-top-left-num">{{ monthTimesList.current }}</div>
              <div class="drill-top-left-context">
                较上月增加{{ monthTimesList.current - monthTimesList.before }}次
              </div>
            </div>
            <div class="drill-top-right">
              <img
                class="drill-top-right-img"
                src="../../../../assets/img/zhSchool/drill/Icon_BYLJ_CS.png"
              />
            </div>
          </div>
        </dv-border-box-1>
      </div>
      <div class="drill-top-context">
        <dv-border-box-1>
          <div class="drill-top-context1">
            <div class="drill-top-left">
              <div class="drill-top-left-title">历史累计人数</div>
              <div class="drill-top-left-num">{{ historyNumList.current }}</div>
              <div class="drill-top-left-context">
                较上月增加{{ historyNumList.current - historyNumList.before }}人
              </div>
            </div>
            <div class="drill-top-right">
              <img
                class="drill-top-right-img"
                src="../../../../assets/img/zhSchool/drill/Icon_LSLJ_RS.png"
              />
            </div>
          </div>
        </dv-border-box-1>
      </div>
      <div class="drill-top-context">
        <dv-border-box-1>
          <div class="drill-top-context1">
            <div class="drill-top-left">
              <div class="drill-top-left-title">历史累计完成率</div>
              <div class="drill-top-left-num">{{ finishRateList.rate }}</div>
              <div class="drill-top-left-context">园所完成率</div>
            </div>
            <div class="drill-top-right">
              <img
                class="drill-top-right-img"
                src="../../../../assets/img/zhSchool/drill/Icon_LSLJ_WCL.png"
              />
            </div>
          </div>
        </dv-border-box-1>
      </div>
    </div>
    <div class="drill-bottom">
      <div class="drill-bottom-left">
        <div class="drill-bottom-left-top">
          <!-- <dv-border-box-1 > -->
          <div class="drill-bottom-left-info">
            <dv-border-box-1>
              <div class="drill-bottom-left-info-tab">
                <div
                  style="width: 650px; display: flex; justify-content: center"
                >
                  <div style="margin-left: 100px">
                    <button
                      v-on:click="handleBodyTest()"
                      :class="
                        baseInfo.infoTag == '1' ? 'status1Blue' : 'status1Black'
                      "
                    >
                      体质测试
                    </button>
                    <div
                      style="
                        width: 80px;
                        display: flex;
                        height: 1px;
                        align-items: center;
                      "
                      :class="
                        baseInfo.infoTag == '1'
                          ? 'status1BtBlue'
                          : 'status1BtBlack'
                      "
                    ></div>
                  </div>
                  <div
                    style="
                      margin-left: 110px;
                      width: 96px;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <button
                      v-on:click="handleSportTest()"
                      :class="
                        baseInfo.infoTag == '2' ? 'status2Blue' : 'status2Black'
                      "
                    >
                      运动测试
                    </button>
                    <div
                      style="width: 80px; display: flex; height: 1px"
                      :class="
                        baseInfo.infoTag == '2'
                          ? 'status2BtBlue'
                          : 'status2BtBlack'
                      "
                    ></div>
                  </div>
                </div>
                <!-- <div class="select-grade"> -->
                <el-select
                  v-model="gradeValue1"
                  @change="changeGradeRight"
                  style="margin-right: 30px; width: 100px,overflow:hidden"
                >
                  <el-option
                    v-for="item in grade_options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <!-- </div> -->
              </div>
              <div
                class="drill-bottom-left-info-data"
                v-if="baseInfo.infoTag == 1"
              >
                <img
                  class="drill-bottom-left-info-data-center"
                  src="../../../../assets/img/zhSchool/drill/Pic_Girl.png"
                />
                <div class="drill-bottom-left-info-data-wquan">
                  <img
                    src="../../../../assets/img/zhSchool/drill/wquan.png"
                    alt=""
                  />
                </div>
                <div class="drill-bottom-left-info-data-nquan">
                  <img
                    src="../../../../assets/img/zhSchool/drill/nquan.png"
                    alt=""
                  />
                </div>
                <div class="drill-bottom-left-info-data-first">
                  <div class="drill-bottom-left-info-data-first-left">
                    <div class="drill-bottom-left-info-data-first-left-style">
                      <img
                        class="drill-bottom-left-info-data-first-left-img"
                        src="../../../../assets/img/zhSchool/drill/Icon_TC_SG.png"
                      />
                    </div>
                    <div class="drill-bottom-left-info-data-first-left-context">
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title
                        "
                        v-if="TZTestList.length > 0"
                      >
                        {{ TZTestList[0].title }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title2
                        "
                        v-if="TZTestList.length > 0"
                      >
                        {{ TZTestList[0].testAvgScore }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title
                        "
                      >
                        园所平均值
                      </div>
                    </div>
                  </div>
                  <div class="drill-bottom-left-info-data-first-right">
                    <div class="drill-bottom-left-info-data-first-left-context">
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title
                        "
                        v-if="TZTestList.length > 0"
                      >
                        {{ TZTestList[1].title }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title2
                        "
                        v-if="TZTestList.length > 0"
                      >
                        {{ TZTestList[1].testAvgScore }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title
                        "
                      >
                        园所平均值
                      </div>
                    </div>
                    <div>
                      <img
                        class="drill-bottom-left-info-data-first-right-img"
                        src="../../../../assets/img/zhSchool/drill/Icon_TC_Weight.png"
                      />
                    </div>
                  </div>
                  <div></div>
                </div>
                <div class="drill-bottom-left-info-data-second">
                  <div class="drill-bottom-left-info-data-first-left">
                    <div class="drill-bottom-left-info-data-first-left-style">
                      <img
                        class="drill-bottom-left-info-data-first-left-img"
                        src="../../../../assets/img/zhSchool/drill/Icon_TC_SL.png"
                      />
                    </div>
                    <div class="drill-bottom-left-info-data-first-left-context">
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title
                        "
                        v-if="TZTestList.length > 0"
                      >
                        {{ TZTestList[2].title }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title2
                        "
                        v-if="TZTestList.length > 0"
                      >
                        {{ TZTestList[2].testAvgScore }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title
                        "
                      >
                        园所平均值
                      </div>
                    </div>
                  </div>
                  <div class="drill-bottom-left-info-data-second-right">
                    <div class="drill-bottom-left-info-data-first-left-context">
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title
                        "
                        v-if="TZTestList.length > 0"
                      >
                        {{ TZTestList[3].title }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title2
                        "
                        v-if="TZTestList.length > 0"
                      >
                        {{ TZTestList[3].testAvgScore }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title
                        "
                      >
                        园所平均值
                      </div>
                    </div>
                    <div>
                      <img
                        class="drill-bottom-left-info-data-first-right-img"
                        src="../../../../assets/img/zhSchool/drill/Icon_TC_BZ.png"
                      />
                    </div>
                  </div>
                  <div></div>
                </div>
                <div class="drill-bottom-left-info-data-second">
                  <div class="drill-bottom-left-info-data-first-left">
                    <div class="drill-bottom-left-info-data-first-left-style">
                      <img
                        class="drill-bottom-left-info-data-first-left-img"
                        src="../../../../assets/img/zhSchool/drill/Icon_TC_FHL.png"
                      />
                    </div>
                    <div class="drill-bottom-left-info-data-first-left-context">
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title
                        "
                        v-if="TZTestList.length > 0"
                      >
                        {{ TZTestList[4].title }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title2
                        "
                        v-if="TZTestList.length > 0"
                      >
                        {{ TZTestList[4].testAvgScore }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title
                        "
                      >
                        园所平均值
                      </div>
                    </div>
                  </div>
                  <div class="drill-bottom-left-info-data-second-right">
                    <div class="drill-bottom-left-info-data-first-left-context">
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title
                        "
                        v-if="TZTestList.length > 0"
                      >
                        {{ TZTestList[5].title }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title2
                        "
                        v-if="TZTestList.length > 0"
                      >
                        {{ TZTestList[5].testAvgScore }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title
                        "
                      >
                        园所平均值
                      </div>
                    </div>
                    <div>
                      <img
                        class="drill-bottom-left-info-data-first-right-img"
                        src="../../../../assets/img/zhSchool/drill/Icon_TC_XW.png"
                      />
                    </div>
                  </div>
                  <div></div>
                </div>
                <div class="drill-bottom-left-info-data-first">
                  <div class="drill-bottom-left-info-data-first-left">
                    <div class="drill-bottom-left-info-data-first-left-style">
                      <img
                        class="drill-bottom-left-info-data-first-left-img"
                        src="../../../../assets/img/zhSchool/drill/Icon_TC_TQQ.png"
                      />
                    </div>
                    <div class="drill-bottom-left-info-data-first-left-context">
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title
                        "
                        v-if="TZTestList.length > 0"
                      >
                        {{ TZTestList[6].title }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title2
                        "
                        v-if="TZTestList.length > 0"
                      >
                        {{ TZTestList[6].testAvgScore }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title
                        "
                      >
                        园所平均值
                      </div>
                    </div>
                  </div>
                  <!-- <div class="drill-bottom-left-info-data-first-right">
                    <div class="drill-bottom-left-info-data-first-left-context">
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title
                        "
                        v-if="TZTestList.length > 0"
                      >
                        {{ TZTestList[7].title }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title2
                        "
                        v-if="TZTestList.length > 0"
                      >
                        {{ TZTestList[7].testAvgScore }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title
                        "
                      >
                        园所平均值
                      </div>
                    </div>
                    <div>
                      <img
                        class="drill-bottom-left-info-data-first-right-img"
                        src="../../../../assets/img/zhSchool/drill/Icon_TC_TQQ.png"
                      />
                    </div>
                  </div> -->
                  <div></div>
                </div>
              </div>
              <div
                class="drill-bottom-left-info-data"
                v-if="baseInfo.infoTag == 2"
              >
                <div class="drill-bottom-left-info-data-TextCenter"></div>
                <!-- <img
                  class="drill-bottom-left-info-data-center"
                  src="../../../assets/img/zhSchool/drill/Pic_Girl.png"
                /> -->
                <div class="drill-bottom-left-info-data-wquan">
                  <img
                    src="../../../../assets/img/zhSchool/drill/wquan.png"
                    alt=""
                  />
                </div>
                <div class="drill-bottom-left-info-data-nquan">
                  <img
                    src="../../../../assets/img/zhSchool/drill/nquan.png"
                    alt=""
                  />
                </div>
                <div class="drill-bottom-left-info-data-first">
                  <div class="drill-bottom-left-info-data-first-left">
                    <div class="drill-bottom-left-info-data-first-left-style">
                      <img
                        class="drill-bottom-left-info-data-first-left-img"
                        src="../../../../assets/img/zhSchool/drill/Icon_YC_PD.png"
                      />
                    </div>
                    <div class="drill-bottom-left-info-data-first-left-context">
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title
                        "
                        v-if="YDTestList.length > 0"
                      >
                        {{ YDTestList[0].title }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title2
                        "
                        v-if="YDTestList.length > 0"
                      >
                        {{ YDTestList[0].testAvgScore }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title
                        "
                      >
                        园所平均值
                      </div>
                    </div>
                  </div>
                  <div class="drill-bottom-left-info-data-first-right">
                    <div class="drill-bottom-left-info-data-first-left-context">
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title
                        "
                        v-if="YDTestList.length > 0"
                      >
                        {{ YDTestList[3].title }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title2
                        "
                        v-if="YDTestList.length > 0"
                      >
                        {{ YDTestList[3].testAvgScore }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title
                        "
                      >
                        园所平均值
                      </div>
                    </div>
                    <div>
                      <img
                        class="drill-bottom-left-info-data-first-right-img"
                        src="../../../../assets/img/zhSchool/drill/Icon_YC_PH.png"
                      />
                    </div>
                  </div>
                  <div></div>
                </div>
                <div class="drill-bottom-left-info-data-second">
                  <div class="drill-bottom-left-info-data-first-left">
                    <div class="drill-bottom-left-info-data-first-left-style">
                      <img
                        class="drill-bottom-left-info-data-first-left-img"
                        src="../../../../assets/img/zhSchool/drill/Icon_YC_MJ.png"
                      />
                    </div>
                    <div class="drill-bottom-left-info-data-first-left-context">
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title
                        "
                        v-if="YDTestList.length > 0"
                      >
                        {{ YDTestList[2].title }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title2
                        "
                        v-if="YDTestList.length > 0"
                      >
                        {{ YDTestList[2].testAvgScore }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title
                        "
                      >
                        园所平均值
                      </div>
                    </div>
                  </div>
                  <div class="drill-bottom-left-info-data-second-right">
                    <div class="drill-bottom-left-info-data-first-left-context">
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title
                        "
                        v-if="YDTestList.length > 0"
                      >
                        {{ YDTestList[1].title }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title2
                        "
                        v-if="YDTestList.length > 0"
                      >
                        {{ YDTestList[1].testAvgScore }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title
                        "
                      >
                        园所平均值
                      </div>
                    </div>
                    <div>
                      <img
                        class="drill-bottom-left-info-data-first-right-img"
                        src="../../../../assets/img/zhSchool/drill/Icon_YC_TY.png"
                      />
                    </div>
                  </div>
                  <div></div>
                </div>
                <div class="drill-bottom-left-info-data-second">
                  <div class="drill-bottom-left-info-data-first-left">
                    <div class="drill-bottom-left-info-data-first-left-style">
                      <img
                        class="drill-bottom-left-info-data-first-left-img"
                        src="../../../../assets/img/zhSchool/drill/Icon_YC_XT.png"
                      />
                    </div>
                    <div class="drill-bottom-left-info-data-first-left-context">
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title
                        "
                        v-if="YDTestList.length > 0"
                      >
                        {{ YDTestList[4].title }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title2
                        "
                        v-if="YDTestList.length > 0"
                      >
                        {{ YDTestList[4].testAvgScore }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title
                        "
                      >
                        园所平均值
                      </div>
                    </div>
                  </div>
                  <div class="drill-bottom-left-info-data-second-right">
                    <div class="drill-bottom-left-info-data-first-left-context">
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title
                        "
                        v-if="YDTestList.length > 0"
                      >
                        {{ YDTestList[5].title }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title2
                        "
                        v-if="YDTestList.length > 0"
                      >
                        {{ YDTestList[5].testAvgScore }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title
                        "
                      >
                        园所平均值
                      </div>
                    </div>
                    <div>
                      <img
                        class="drill-bottom-left-info-data-first-right-img"
                        src="../../../../assets/img/zhSchool/drill/Icon_YC_Wk.png"
                      />
                    </div>
                  </div>
                  <div></div>
                </div>
                <div class="drill-bottom-left-info-data-first">
                  <div class="drill-bottom-left-info-data-first-left">
                    <div class="drill-bottom-left-info-data-first-left-style">
                      <img
                        class="drill-bottom-left-info-data-first-left-img"
                        src="../../../../assets/img/zhSchool/drill/Icon_YC_WKJ.png"
                      />
                    </div>
                    <div class="drill-bottom-left-info-data-first-left-context">
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title
                        "
                        v-if="YDTestList.length > 0"
                      >
                        {{ YDTestList[7].title }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title2
                        "
                        v-if="YDTestList.length > 0"
                      >
                        {{ YDTestList[7].testAvgScore }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-left-context-title
                        "
                      >
                        园所平均值
                      </div>
                    </div>
                  </div>
                  <div class="drill-bottom-left-info-data-first-right">
                    <div class="drill-bottom-left-info-data-first-left-context">
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title
                        "
                        v-if="YDTestList.length > 0"
                      >
                        {{ YDTestList[6].title }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title2
                        "
                        v-if="YDTestList.length > 0"
                      >
                        {{ YDTestList[6].testAvgScore }}
                      </div>
                      <div
                        class="
                          drill-bottom-left-info-data-first-right-context-title
                        "
                      >
                        园所平均值
                      </div>
                    </div>
                    <div>
                      <img
                        class="drill-bottom-left-info-data-first-right-img"
                        src="../../../../assets/img/zhSchool/drill/Icon_YC_WKS.png"
                      />
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </dv-border-box-1>
          </div>
          <!-- </dv-border-box-1> -->
          <div class="drill-bottom-left-table">
            <dv-border-box-1>
              <div class="drill-bottom-left-table-left">
                <div class="drill-bottom-left-bottom-echar-tab">
                  <button
                    v-on:click="handleBodyTestEchart()"
                    :class="[
                      baseInfo.echartType == 'foundation'
                        ? 'echart1Blue'
                        : 'echart1Black',
                    ]"
                    style="margin-left: 25px"
                  >
                    体质测试
                  </button>
                  <button
                    v-on:click="handleSportTestEchart()"
                    :class="[
                      baseInfo.echartType == 'sport'
                        ? 'echart1Blue'
                        : 'echart1Black',
                    ]"
                    style="margin-left: 25px"
                  >
                    运动测试
                  </button>
                </div>
                <div
                  class="echart-tab-context"
                  v-if="baseInfo.echartType == 'foundation'"
                >
                  <span class="echart-tab-title">全国体质测试对比</span>
                  <div style="flex: 1"></div>
                  <span class="inputDemo">
                    <el-select
                      v-model="foundationIndex"
                      style="margin-right: 26px; width: 110px,overflow:hidden"
                      @change="changeFoundationIndex"
                    >
                      <el-option
                        v-for="item in foundationIndexList"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </span>
                </div>
                <div class="echart-tab-context" v-else>
                  <span class="echart-tab-title">全国运动测试对比</span>
                  <div style="flex: 1"></div>
                  <span class="inputDemo1">
                    <el-select
                      v-model="sportSelOneData"
                      style="margin-right: 2px; width: 58px,overflow:hidden"
                      @change="changeSportSelectOne"
                    >
                      <el-option
                        v-for="item in sportSelectOneList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </span>
                  <span class="inputDemo">
                    <el-select
                      v-model="sportSelTwoData"
                      style="margin-right: 25px; width: 58px,overflow:hidden"
                      @change="changeSportSelectTwo"
                    >
                      <el-option
                        v-for="item in sportSelectTwoList"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </span>
                </div>
                <!-- <barChart :barData="barData" /> -->
                <div id="barChart" class="myBarChart"></div>
              </div>
            </dv-border-box-1>
            <dv-border-box-1>
              <div class="drill-bottom-left-bottom-score-tab">
                <div class="echart-tab-score-context">
                  <span class="echart-tab-title">区域评分排名</span>
                  <div style="flex: 1"></div>
                </div>
                <div class="drill-bottom-left-bottom-score-table">
                  <div class="drill-list-box">
                    <div class="drill-item-name">
                      <ul>
                        <li>序号</li>
                        <li>园所名称</li>
                        <li>测试人数</li>
                        <li>测试积分</li>
                      </ul>
                    </div>
                    <div class="drill-item-content">
                      <ul
                        v-for="(item, index) in schoolScoreRankList"
                        :key="index"
                      >
                        <li>{{ index + 1 }}</li>
                        <li>{{ item.schoolName }}</li>
                        <li>{{ item.testCount || 0 }}</li>
                        <li>{{ item.integral || 0 }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </dv-border-box-1>
          </div>
        </div>
      </div>
      <div class="drill-bottom-right">
        <dv-border-box-1>
          <div class="drill-bottom-right-context">
            <div class="drill-bottom-right-title">32项指标对比</div>
            <div class="drill-bottom-right-color">
              <ul>
                <li>
                  全国
                  <span class="color1"></span>
                </li>
                <li>
                  全省
                  <span class="color2"></span>
                </li>
                <li>
                  园所
                  <span class="color3"></span>
                </li>
              </ul>
            </div>
            <sunburstChart :cdata="sunData" />
          </div>
        </dv-border-box-1>
      </div>
    </div>
  </div>
</template>
<script>
// import barChart from "@/views/zhSchool/routeCon/barChart.vue";
import sunburstChart from "@/views/zhSchool/routeCon/sunburstChart.vue";
export default {
  components: {
    // barChart,
    sunburstChart,
  },
  data() {
    return {
      activeName: "first",
      className: "foundation",
      monthTestNum: 0.0,
      baseInfo: {
        infoTag: "1",
        echartType: "foundation",
        areaOptions: [
          {
            value: "选项1",
            label: "黄金糕",
          },
          {
            value: "选项5",
            label: "北京烤鸭",
          },
        ],
        value: "",
      },
      sportSelectOneList: [
        {
          value: "sport1",
          label: "小班",
        },
        {
          value: "sport2",
          label: "中班",
        },
        {
          value: "sport3",
          label: "大班",
        },
      ],
      sportSelectTwoList: [
        {
          id: "1",
          title: "跑动",
        },
        {
          id: "2",
          title: "平衡",
        },
        {
          id: "3",
          title: "敏捷",
        },
        {
          id: "4",
          title: "跳跃",
        },
        {
          id: "5",
          title: "协调",
        },
        {
          id: "6",
          title: "力量",
        },
        {
          id: "7",
          title: "物控-脚",
        },
        {
          id: "8",
          title: "物控-手",
        },
      ],
      sportSelOneData: "sport1",
      sportSelTwoData: "跑动",
      rankingList: [],
      echartType: "foundation",
      type: "foundation",
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          // left: '3%',
          // right: '4%',
          // bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: ["全国均值", "全省均值", "园所均值"],
        },
        series: [
          {
            name: "",
            type: "bar",
            data: [18203, 23489, 29034],
          },
        ],
      },
      grade_options: [],
      gradeValue1: "全部班级",
      gradeId1: "",
      monthTimesList: {},
      monthNumList: {},
      historyNumList: {},
      finishRateList: {},
      TZTestList: {},
      YDTestList: {},
      schoolScoreRankList: {},
      foundationIndexList: {},
      thirtyTwoIndexChartList: {},
      sportIndexlist: {},
      foundationIndex: "",
      sunburstChartOption: {},
      sunData: [],
      barData: {},
    };
  },
  mounted() {
    // 加载默认主题颜色
    window.document.documentElement.setAttribute("data-theme", "base");
    this.init();
    // this.drawLine();
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("barChart"));
      // 绘制图表
      myChart.setOption(this.barData);
    },
    init() {
      this.geteGrowthTrainingTestdateChartMonthTimes("monthTimes");
      this.geteGrowthTrainingTestdateChartMonthNum("monthNum");
      this.geteGrowthTrainingTestdateChartHistoryNum("historyNum");
      this.geteGrowthTrainingtesTrateChart();
      this.getRankingGradeList();
      this.geteGrowthTrainingSchoolScoreRank(1, 5);
      this.geteGrowthTrainingThirtyTwoIndexChart();
      this.geteGrowthTrainingFoundationIndexList();
    },
    //type 代表第几个框
    geteGrowthTrainingTestdateChartMonthTimes(type) {
      this.api.zhSchool
        .geteGrowthTrainingTestdateChartMonthTimes({ type: type })
        .then((res) => {
          this.monthTimesList = res.data;
        });
    },
    //type 代表第几个框
    geteGrowthTrainingTestdateChartMonthNum(type) {
      this.api.zhSchool
        .geteGrowthTrainingTestdateChartMonthNum({ type: type })
        .then((res) => {
          this.monthNumList = res.data;
        });
    },
    //type 代表第几个框
    geteGrowthTrainingTestdateChartHistoryNum(type) {
      this.api.zhSchool
        .geteGrowthTrainingTestdateChartHistoryNum({ type: type })
        .then((res) => {
          this.historyNumList = res.data;
        });
    },
    // 获取完成率
    geteGrowthTrainingtesTrateChart() {
      this.api.zhSchool.geteGrowthTrainingtesTrateChart().then((res) => {
        this.finishRateList = res.data;
      });
    },
    // 获取班级列表
    getRankingGradeList() {
      this.api.zhSchool.geteRankingGradeList().then((res) => {
        console.log(res.data);
        this.grade_options = res.data.data;
        this.gradeId1 = res.data.data[0].id;
        this.gradeValue1 = res.data.data[0].name;
        this.geteRankingTZTestRate(this.gradeId1);
        this.geteRankingSportTestRate(this.gradeId1);
      });
    },
    // 获取体质数据
    geteRankingTZTestRate(gradeId) {
      this.api.zhSchool
        .geteRankingTZTestRate({
          gradeId: gradeId,
        })
        .then((res) => {
          this.TZTestList = res.data;
        });
    },
    // 获取运动数据
    geteRankingSportTestRate(gradeId) {
      this.api.zhSchool
        .geteRankingSportTestRate({
          gradeId: gradeId,
        })
        .then((res) => {
          this.YDTestList = res.data;
        });
    },
    geteGrowthTrainingSchoolScoreRank(pageNum, pageSize) {
      this.api.zhSchool
        .geteGrowthTrainingSchoolScoreRank(pageNum, pageSize)
        .then((res) => {
          this.schoolScoreRankList = res.data.data;
        });
    },
    growthTrainingIndexCharts(id, type) {
      this.api.zhSchool.growthTrainingIndexCharts({ id, type }).then((res) => {
        this.barData = res.data;
        // console.log(this.barData,'this.barData')
        this.setOption(this.barData);
      });
    },
    geteGrowthTrainingThirtyTwoIndexChart() {
      this.api.zhSchool
        .geteGrowthTrainingThirtyTwoIndexChart({ pageNum: 1, pageSize: 5 })
        .then((res) => {
          console.log(res.data, "我看看什么情况");
          this.thirtyTwoIndexChartList = res.data;
          this.sunData = res.data;
        });
    },
    //运动测试sport1下拉框
    geteGrowthTrainingSportIndexlist(id, type) {
      this.api.zhSchool
        .geteGrowthTrainingSportIndexlist({ id, type })
        .then((res) => {
          this.sportIndexlist = res.data.data;
          this.growthTrainingIndexCharts(
            this.foundationIndexList[0].id,
            this.baseInfo.echartType
          );
        });
    },
    geteGrowthTrainingFoundationIndexList() {
      this.api.zhSchool.geteGrowthTrainingFoundationIndexList().then((res) => {
        this.foundationIndexList = res.data;
        this.foundationIndex = res.data[0].title;
        this.growthTrainingIndexCharts(
          this.foundationIndexList[0].id,
          this.baseInfo.echartType
        );
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleBodyTest() {
      this.baseInfo.infoTag = 1;
    },
    handleSportTest() {
      this.baseInfo.infoTag = 2;
    },
    handleBodyTestEchart() {
      this.baseInfo.echartType = "foundation";
      this.geteGrowthTrainingFoundationIndexList();
    },
    handleSportTestEchart() {
      this.baseInfo.echartType = "sport";
      this.geteGrowthTrainingFoundationIndexList();
    },
    // 选择地区
    changeArea(item) {
      this.month_value = item;
      // this.getRankingClassData();
    },
    changeGradeRight(val) {
      this.gradeId1 = val;
      this.geteRankingTZTestRate(this.gradeId1);
      this.geteRankingSportTestRate(this.gradeId1);
    },
    changeFoundationIndex(val) {
      this.foundationIndex = val;
      // this.geteGrowthTrainingSportIndexlist(val,this.echartType);
      // this.geteGrowthTrainingIndexChart(val, this.echartType);
      this.growthTrainingIndexCharts(val, this.baseInfo.echartType);
    },
    changeSportSelectOne(val) {
      this.sportSelOneData = val;
      // this.geteGrowthTrainingSportIndexlist();
      this.sportSelTwoData = "跑动";
      this.growthTrainingIndexCharts(
        this.sportSelectTwoList[0].id,
        this.baseInfo.echartType
      );
    },
    changeSportSelectTwo(val) {
      this.sportSelTwoData = val;
      console.log(this.sportSelTwoData, "this.sportSelTwoData");
      this.growthTrainingIndexCharts(
        this.sportSelTwoData,
        this.baseInfo.echartType
      );
    },
    setOption(newVal) {
      // this.barData ={
      // let xData = [];
      // let yData = [];
      // for (const key in newVal) {
      //   yData.push(key);
      //   xData.push(newVal[key]);
      // }
      console.log(newVal, "newVal");
      this.barData = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          show: true,
          splitLine: { show: false },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#BDD1DA", //这里用参数代替了
            },
          },
        },
        yAxis: {
          type: "category",
          data: newVal.xdata,
          axisLabel: {
            show: true,
            textStyle: {
              color: "#85B6CA", //这里用参数代替了
            },
          },
        },
        series: [
          {
            name: "",
            type: "bar",
            data: newVal.ydata,
            itemStyle: {
              normal: {
                color: (option) => {
                  if (option.name === "全国均值") {
                    return "#8FE5FF";
                  } else if (option.name === "全省均值") {
                    return "#B9FDFB";
                  } else if (option.name === "园所均值") {
                    return "#FFE08C";
                  } else {
                    return "#fff";
                  }
                },
              },
            },
          },
        ],
      };
      // }
      let myChart = this.$echarts.init(document.getElementById("barChart"));
      // 绘制图表
      myChart.setOption(this.barData, true);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/drill/_handle.scss";
.drill {
  width: 1620px;
  height: 1080px;
  margin-left: 75px;
  // margin-top: -40px;
}
.drill-top {
  display: flex;
}
.drill-top-context {
  display: flex;
  flex-direction: row;
  // background-image: url("../../../../assets/img/zhSchool/drill/Pic_Bg_LJCS.png");
  width: 350px;
  height: 189px;
  background-size: cover;
  margin-right: 42px;
}
.drill-top-context1 {
  display: flex;
  flex-direction: row;
  background-image: url("../../../../assets/img/zhSchool/drill/Pic_Bg_LJRS.png");
  width: 350px;
  height: 189px;
  background-size: cover;
  margin-right: 42px;
}
.drill-top-left {
  padding-left: 45px;
  padding-top: 38px;
  width: 221px;
}
.drill-top-left-title {
  width: 140px;
  height: 19px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #c3e8ff;
  line-height: 24px;
}
.drill-top-left-num {
  width: 124px;
  height: 38px;
  font-size: 40px;
  font-family: Impact;
  font-weight: 400;
  color: #dff3ff;
  padding-top: 19px;
}
.drill-top-left-context {
  width: 120px;
  height: 15px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #a3d3e3;
  padding-top: 19px;
}
.drill-top-right {
  flex: 1;
  align-items: center;
  display: flex;

}
.drill-top-right-img {
  //padding-top: 77px;
  //padding-right: 34px;
   width:76px;
   height:76px;
}
.drill-bottom {
  display: flex;
}
.drill-bottom-left-info {
  display: flex;
  width: 898px;
  height: 503px;
  flex-direction: column;
  // justify-content: center;
  background-image: url("../../../../assets/img/zhSchool/drill/Pic_Bg_CSSJ.png");
  background-size: cover;
}
.drill-bottom-right {
  display: flex;
  flex-direction: column;
  width: 615px;
  height: 830px;
  margin-left: 9px;
  background-image: url("../../../../assets/img/zhSchool/drill/Pic_Bg_32ZB.png");
  background-size: cover;
  align-items: center;
}
.drill-bottom-right-context {
  display: flex;
  flex-direction: column;
  width: 615px;
  height: 830px;
  margin-left: 6px;
  // background-image: url("../../../../assets/img/zhSchool/drill/Pic_Bg_32ZB.png");
  background-size: cover;
}
.drill-bottom-left-table {
  display: flex;
  width: 449px;
}
.drill-bottom-left-table-left {
  width: 449px;
  height: 332px;
  background-image: url("../../../../assets/img/zhSchool/drill/Pic_Bg_CSBD.png");
  background-size: cover;
}
.drill-bottom-left-bottom-score-tab {
  width: 445px;
  height: 332px;
  margin-left: 5px;
  background-image: url("../../../../assets/img/zhSchool/drill/Pic_Bg_PFPM.png");
  background-size: cover;
}
.drill-bottom-left-info-tab {
  margin-top: 45px;
  display: flex;
  justify-content: center;
}
.drill-bottom-left-info-data {
  width: 805px;
  height: 347px;
  margin-left: 48px;
  margin-top: 20px;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-image: url("../../../../assets/img/zhSchool/drill/Pic_Bg_Child1.png");
}
.drill-bottom-left-info-data-first {
  display: flex;
  padding-left: 24px;
  margin-top: 12px;
}
.drill-bottom-left-info-data-first-left-style {
  display: flex;
  align-items: center;
}
.drill-bottom-left-info-data-first-left {
  display: flex;
  width: 120px;
  margin-left: 22px;
  align-items: center;
}
.drill-bottom-left-info-data-first-left-img {
  width: 74px;
  height: 74px;
  object-fit: cover;
}
.drill-bottom-left-info-data-first-right-img {
  width: 74px;
  height: 74px;
  object-fit: cover;
}
.drill-bottom-left-info-data-first-left-context {
  display: flex;
  flex-direction: column;
}
.drill-bottom-left-info-data-first-left-context-title {
  width: 120px;
  height: 13px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #a3d3e3;
}
.drill-bottom-left-info-data-first-left-context-title2 {
  width: 120px;
  height: 15px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  margin-top: 7px;
  margin-bottom: 7px;
}
.drill-bottom-left-info-data-first-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-right: 22px;
}
.drill-bottom-left-info-data-first-right-context-title {
  justify-content: flex-end;
  display: flex;
  width: 120px;
  height: 13px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #a3d3e3;
}
.drill-bottom-left-info-data-first-right-context-title2 {
  justify-content: flex-end;
  display: flex;
  width: 120px;
  height: 15px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  margin-top: 7px;
  margin-bottom: 7px;
}
.drill-bottom-left-info-data-second {
  display: flex;
  padding-left: 2px;
  margin-top: 8px;
}
.drill-bottom-left-info-data-second-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2px;
}
.drill-bottom-left-info-data-center {
  margin-left: 330px;
  margin-top: 72px;
  width: 146px;
  height: 208px;
  position: absolute;
}
.drill-bottom-left-info-data-TextCenter {
  margin-left: 325px;
  margin-top: 100px;
  width: 155px;
  height: 155px;
  position: absolute;
  background: url(../../../../assets/img/zhSchool/drill/prow.png) 0px 0px no-repeat;
  animation: person-slow 3s infinite step-start;
  background-size: 3000% 100%;
}
@-webkit-keyframes person-slow {
  /* 慢 */
  0% {
    background-position: 0 0;
  }
  3% {
    background-position: -155px 0px;
  }
  5% {
    background-position: -310px 0px;
  }
  8% {
    background-position: -465px 0px;
  }
  11% {
    background-position: -620px 0px;
  }
  14% {
    background-position: -775px 0px;
  }
  17% {
    background-position: -930px 0px;
  }
  20% {
    background-position: -1085px 0px;
  }
  23.3% {
    background-position: -1240px 0px;
  }
  26.6% {
    background-position: -1395px 0px;
  }
  39.9% {
    background-position: -1550px 0px;
  }
  43% {
    background-position: -1705px 0px;
  }
  46.3% {
    background-position: -1860px 0px;
  }
  49.9% {
    background-position: -2015px 0px;
  }
  53% {
    background-position: -2170px 0px;
  }
  56.3% {
    background-position: -2325px 0px;
  }
  59.9% {
    background-position: -2480px 0px;
  }
  63% {
    background-position: -2635px 0px;
  }
  66.6% {
    background-position: -2790px 0px;
  }
  69.9% {
    background-position: -2945px 0px;
  }
  73% {
    background-position: -3100px 0px;
  }
  77% {
    background-position: -3255px 0px;
  }
  81% {
    background-position: -3410px 0px;
  }
  84.3% {
    background-position: -3565px 0px;
  }
  87.6% {
    background-position: -3720px 0px;
  }
  90.9% {
    background-position: -3875px 0px;
  }
  92.2% {
    background-position: -4030px 0px;
  }
  95.5% {
    background-position: -4185px 0px;
  }
  98.8% {
    background-position: -4340px 0px;
  }
  99% {
    background-position: -4650px 0px;
  }
  100% {
    background-position: 0px 0px;
  }
}
.drill-bottom-left-info-data-nquan {
  margin-left: 220px;
  margin-top: -8px;
  width: 146px;
  height: 208px;
  position: absolute;
  img {
    width: 360px;
    height: 360px;
    animation: rotation 5s ease-in infinite alternate;
    -webkit-animation: rotation 5s ease-in infinite alternate;
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.drill-bottom-left-info-data-wquan {
  margin-left: 220px;
  margin-top: -8px;
  width: 146px;
  height: 208px;
  position: absolute;
  img {
    width: 360px;
    height: 360px;
    animation: rotation1 6s ease-in infinite alternate;
    -webkit-animation: rotation1 6s ease-in infinite alternate;
  }
}
@keyframes rotation1 {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
  }
}
.drill-bottom-left-bottom {
  display: flex;
}
.drill-bottom-left-bottom-echar {
  display: flex;
}
.drill-bottom-left-bottom-echar-tab {
  display: flex;
  justify-content: center;
  margin-bottom: 1px;
}
.echar-tab-bt {
  margin-right: 35px;
}
.echart-tab-context {
  display: flex;
  height: 50px;
  align-items: center;
  margin-top: 10px;
}
.echart-tab-score-context {
  display: flex;
  height: 21px;
  align-items: center;
  padding-top: 19px;
}
.echart-tab-title {
  width: 164px;
  height: 21px;
  margin-left: 41px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #c3e8ff;
  margin-bottom: 4px;
}
.score-tab-context {
  display: flex;
}
.score-tab-title {
  width: 164px;
  height: 21px;
  margin-left: 41px;
  margin-top: 25px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #c3e8ff;
}
.echart-tab-title .el-input__inner {
  width: 122px;
  height: 30px;
  background: #dff3ff;
  margin-top: 10px;
}
.drill-bottom-left-bottom-score-table {
  width: 445px;
  display: flex;
  justify-content: center;
  margin-top: 14px;
}
.drill-list-box {
  margin-top: 8px;
  // margin-left: 30px;
  width: 363px;
  height: 246px;
  .drill-item-name {
    ul {
      display: flex;
      justify-content: space-around;
      height: 41px;
      background: #6486a630;
      li {
        flex: 1;
        text-align: center;
        line-height: 41px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #c3e8ff;
      }
    }
  }
  .drill-item-content {
    width: 100%;
    height: 200px;
    overflow: hidden;
    ul {
      display: flex;
      justify-content: space-around;
      height: 40px;
      li {
        flex: 1;
        text-align: center;
        line-height: 40px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #a3d3e3;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    ul:nth-child(even) {
      background: #6486a630;
      li {
        color: #fff;
      }
    }
  }
  .class1 {
    margin-left: 100px;
    background: #59c1e4;
  }
  .class2 {
    margin-left: 160px;
    background: #7f9096;
  }
  .inputDemo {
    width: 30px;
    display: flex;
  }
  .sel {
    position: absolute;
    clip: rect(2px 90px 30px 2px);
    width: 90px;
    height: 28px;
    line-height: 28px;
    font-size: 15px;
    font-weight: bold;
  }
}
.status1Blue {
  background: none;
  color: #ffebd2;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
.status1Black {
  background: none;
  color: #dff3ff;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
.status2Blue {
  background: none;
  color: #ffebd2;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
.status2Black {
  background: none;
  color: #dff3ff;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
.status1BtBlue {
  background: #ffebd2;
}
.status1BtBlack {
  background: none;
}
.status2BtBlue {
  background: #ffebd2;
}
.status2BtBlack {
  background: none;
}
.echart1Blue {
  background: none;
  color: #ffffff;
  border: none;
  width: 100px;
  height: 42px;
  background-image: url("../../../../assets/img/zhSchool/drill/Btn_Bg_Cho.png");
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  font-size: 16px;
  margin-top: 15px;
}
.echart1Black {
  background: none;
  color: #c3e8ff;
  border: none;
  width: 90px;
  height: 32px;
  background-image: url("../../../../assets/img/zhSchool/drill/Btn_Bg_UnC.png");
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  font-size: 16px;
  margin-top: 20px;
}
.echart2Blue {
  background: none;
  color: #ffffff;
  border: none;
  width: 100px;
  height: 42px;
  background-image: url("../../../../assets/img/zhSchool/drill/Btn_Bg_Cho.png");
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  font-size: 16px;
  margin-top: 15px;
}
.echart2Black {
  background: none;
  color: #c3e8ff;
  border: none;
  width: 90px;
  height: 32px;
  background-image: url("../../../../assets/img/zhSchool/drill/Btn_Bg_UnC.png");
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  font-size: 16px;
  margin-top: 20px;
}
.drill-bottom-right-title {
  height: 100px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #c3e8ff;
}
.drill-bottom-right-color {
  margin-left: 30px;
  ul {
    display: flex;
    li {
      display: flex;
      align-items: center;
      span {
        display: block;
        background: red;
        width: 40px;
        height: 20px;
        border-radius: 20px;
        margin: 0 10px;
      }
      .color1 {
        background: #ff6d75;
      }
      .color2 {
        background: #ffb86a;
      }
      .color3 {
        background: #5d89d5;
      }
    }
  }
}
.el-input--suffix .el-input__inner {
  border: none;
  background: transparent;
  //width: 100px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #c3e8ff;
  overflow: hidden;
  text-align: right;
  padding-left: 0px;
}
.el-icon-arrow-up:before {
  content: "";
  background: url(../../../../assets/img/zhSchool/ranking/Icon_Arrow_Down.png)
    center center no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.el-select-dropdown__item.hover {
  background: #333333;
}
.el-popper {
  background: #333333;
}

.el-select-dropdown__item.hover {
  background: transparent;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #c3e8ff;
}
.el-select-dropdown__item {
  background: transparent;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #c3e8ff;
}
.inputDemo1 .el-input--suffix .el-input__inner {
  border: none;
  background: transparent;
  width: 70px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #c3e8ff;
  overflow: hidden;
  text-align: right;
  margin: 0 0;
}
.myBarChart {
  height: 260px;
  // padding-top: -40px;
}
// .dv-border-box-1 .border-box-content {
//     position: relative;
//     width: 100%;
//     height: 100%;
//     display: flex;
// }
</style>
<style lang="scss" >
 .border-box-content .el-input--suffix .el-input__inner {
  border: none;
  background: transparent;
  //width: 100px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #c3e8ff;
  overflow: hidden;
  text-align: right;
  padding-left: 0px;
}
border-box-content .el-icon-arrow-up:before {
  content: "";
  background: url(../../../../assets/img/zhSchool/ranking/Icon_Arrow_Down.png)
    center center no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
