<!--
 * @Descripttion: 今天，你进步了吗？
 * @version: V1.0
 * @Author: lily
 * @Date: 2022-05-02 14:37:55
 * @LastEditors: lily
 * @LastEditTime: 2022-05-06 18:58:28
-->
<template>
  <div class="drill">
    <component :is="currentRole" />
  </div>
</template>
<script>
// 海洋模式
import oceanDrill from "../html/ocean/oceanDrill.vue";
// 星空模式
import starryDrill from "../html/starry/starryDrill.vue";
export default {
  components: {
    // barChart,
    oceanDrill,
    starryDrill,
  },
  data() {
    return {
      currentRole:"",
    };
  },
  mounted() {
    // 获取默认智慧风格
    this.getStyle();
    // 加载默认主题颜色
    // window.document.documentElement.setAttribute("data-theme", this.$store.state.skinpeeler);
    
  },
   methods: {
    getStyle() {
      this.api.zhSchool
        .schoolDetail(this.$store.state.userInfo.schoolId)
        .then((res) => {
          if (res.errCode == 0) {
            if (res.data.wisdomScreenStyleId == 1) {
              this.currentRole = "starryDrill";
              
            } else if (res.data.wisdomScreenStyleId == 2) {
              this.currentRole = "oceanDrill";
            }else{
              this.currentRole = "starryDrill";
            }
            
          }
          console.log(res, "1111111111111");
        });
    },
  },
 
};
</script>
<style lang="scss">
  .drill{
  width:100%;
  height:100%;}
</style>

