<!--
 * @Descripttion: 今天，你进步了吗？
 * @version: V1.0
 * @Author: lily
 * @Date: 2022-05-05 08:47:37
 * @LastEditors: lily
 * @LastEditTime: 2022-05-05 10:52:16
-->
<template>
  <div id="sunChart" class="myChart"></div>
</template>

<script>
export default {
  name: "sunburstChart",
  props: {
    cdata: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: {},
    };
  },
  mounted() {
    console.log("this.cdata-----", this.cdata);
    this.drawLine();
  },

  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("sunChart"));
      // 绘制图表
      myChart.setOption(this.options);
    },
  },
  watch: {
    cdata: {
      handler(newVal) {
        this.options = {
          
          series: {
            type: "sunburst",
            data: newVal,
            radius: [0, "95%"],
            sort: undefined,
            emphasis: {
              focus: "ancestor",
            },
            levels: [
              {},
              {
                r0: "15%",
                r: "35%",
                itemStyle: {
                  borderWidth: 2,
                },
                label: {
                  rotate: "tangential",
                },
              },
              {
                r0: "35%",
                r: "70%",
                label: {
                  align: "right",
                },
              },
              {
                r0: "70%",
                r: "72%",
                label: {
                  position: "outside",
                  padding: 3,
                  silent: false,
                },
                itemStyle: {
                  borderWidth: 3,
                },
              },
            ],
          },
        };
        this.drawLine();
      },
      immediate: false,
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.myChart {
  width: 100%;
  height: 100%;
  margin: 0 auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>>
